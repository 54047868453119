@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, 'Thonburi', sans-serif;
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE 10+ */
}

@font-face {
  font-family: 'Activity-Price';
  src: url('./fonts/Thonburi-new.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url('./fonts/Prompt-Regular.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'Prompt SemiBold';
  src: url('./fonts/Prompt-SemiBold.ttf');
  font-style: normal;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

#launcher {
  display: none !important;
}
#cnzz_stat_icon_1280128753 {
  display: none !important;
}
// .taro-tabbar__tabbar {
//   display: none !important;
// }
.clearWidth {
  width: auto !important;
}

.taro__modal {
  position: relative;
  z-index: 999999;
}
// antd mobile
:root:root {
  --adm-color-primary: #fe2c55;
}

@media screen and (min-width: 40rem) {
  html, body {
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      width: 18.75rem;
  }
}