.popup_contents_ttAuth{
    display: flex;
    flex-direction: column;
}
.auth_benifit_title{
    color: #333333;
    font-size: 32px;
    font-weight: bold;
    padding-bottom: 10px;
}
.auth_benifit_contents{
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
}
.benifit_single{
    display: flex;
    align-items: center;
    padding-top: 30px;
}
.green_logo{
    width: 32px;
    height: 32px;
    margin-right: 20px;
    flex-shrink: 0;
}
.benifit_text{
    color: #333333;
    font-size: 28px;
}
.auth_hint_text{
    color: #FF6F00;
    font-size: 26px;
    padding-bottom: 28px
}