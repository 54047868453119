.popup_contents{
    display: flex;
    flex-direction: column;

}
.bind_warning{
    color: #666666;
    font-size: 32px;
}
.phone_title{
    color: #908B8B;
    font-size: 26px;
    padding-top: 40px;
    padding-bottom: 28px;
}
.phone_wrap{
    display: flex;
    background-color: #F4F5F7;
    border-radius: 16px;
    width: 514px;
    height: 76px;
    align-items: center;
    margin-bottom: 32px;
}
.phone_contry{
    font-weight: bold;
    font-size: 30px;
    padding-left: 24px;
    width: 120px;
}
.phone_input{
    padding-left: 12px;
    font-size: 30px;
}
.sms_wrap{
    display: flex;
    justify-content: space-between;
}
.sms_input_wrap{
    width: 338px;
    height: 76px;
    background-color: #F4F5F7;
    border-radius: 16px;
    display: flex;
    align-items: center;
}
.sms_input{
    padding-left: 24px;
    font-size: 26px;
}
.sms_send_wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 144px;
    height: 76px;
    border-radius: 16px;
    border: 2px solid #FF2646;
}
.sms_send_text{
    color: #FF2646;
    font-size: 30px;
}
.clearBtn {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    align-items: center !important;
  
    &::after {
      border: 0 !important;
    }
} 
.disabled {
    opacity: 0.2;
}
.protocol {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 60px;
    padding-top: 24px;
  }
  .protocol_checkbox_wrap {
    display: flex;
    margin-right: 16px;
    height: 48px;
    align-items: center;
  }
  .protocol_checkbox {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .protocol_checkbox_icon {
    width: 26px;
    height: 26px;
  }
  .protocol_text{
    width: 472px;
    line-height: 40px;
  }
  .protocol_txt {
    color: #908B8B;
    font-size: 26px;
  }
  .protocol_link {
    color: #478BFF;
    font-size: 26px;
    padding-left: 4px;
    padding-right: 4px;
  }
.bind_hint_text{
    color: #FF6F00;
    font-size: 26px;
    padding-bottom: 28px;
}