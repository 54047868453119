
.baseModal_mask{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.1);
}
.baseModal_main{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 602px;
    background-color: white;
    top: 50%;
    left: 50%;
    margin-left: -301px;
    margin-top: -60%;
    align-items: center;
    border-radius: 20px;
}
.title{
    display: flex;
    flex-direction: column;
    width: 602px;
    justify-content: center;
    align-items: center;
}
.title_wrap{
    display: flex;
    width: 602px;
    height: 128px;
    justify-content: center;
    align-items: center;
}
.authLogo{
    width: 234px;
    height: 56px;
    margin-bottom: 12px;
}
.bindLogo{
    width: 118px;
    height: 62px;
}
.baseModal_title_text{
    font-size: 36px;
    font-weight: bold;
    padding-left: 16px;
    line-height: 36px;
}
.title_line{
    width: 514px;
    height: 2px;
    background-color: #FFC5CE;
    margin-bottom: 32px;
}
.baseContent{
    width: 514px;
}
.button{
    width: 514px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF2646;
    border-radius: 16px;
}
.button_gray{
    width: 514px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF2646;
    opacity: 0.5;
    border-radius: 16px;
}
.button_text{
    color: white;
    font-size: 32px;
}
.close_two{
    display: flex;
    justify-content: space-between;
    width: 480px;
    padding-top: 12px;
    padding-bottom: 36px;
}
.close_one{
    display: flex;
    justify-content: center;
    width: 480px;
    padding-top: 12px;
    padding-bottom: 36px;
}
.close_left_text{
    color: #999999;
    font-size: 26px;
    padding-bottom: 10px;
}
.close_right_text{
    color: #999999;
    font-size: 26px;
    padding-bottom: 10px;
}