.popup_contents_combine{
    display: flex;
    flex-direction: column;
}
.auth_combine_title{
    color: #666666;
    font-size: 32px;
}
.auth_combine_contents{
    display: flex;
    max-height: 220px;
    flex-direction: column;
    padding-bottom: 60px;
}
.combine_single{
    background-color: #F4F5F7;
    width: 514px;
    min-height: 76px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}
.combine_text{
    font-weight: bold;
    font-size: 32px;
}